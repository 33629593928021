import React from "react";
import "./style.css";

function Footer() {
  return (
    <footer className="footer">
      <span>Rachel Wanke CopyRight 2021</span>
    </footer>
  );
}

export default Footer;