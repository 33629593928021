import React from "react";

import Container from "../components/Container";
// import Row from "../components/Row";
// import Col from "../components/Col";
import FlipCard from "../components/FlipCard";
import Row from "../components/Row";
import Wrapper from "../components/Wrapper";
import "./css/skills.css"



function Skills() {
    return(
    <div>
        <section id="skills" className="skillSection">
            
            <Wrapper className="wrapper">
            <Container>
            <h1>Skills</h1>
            <FlipCard />
            </Container>
            </Wrapper>
            
            
        </section>










    </div>


    )







}


export default Skills;